import React from 'react'

function udesh() {
  return (
    <>
    <section id="udesh">
      <div className="container-fluid">
        <h5 className="text-center font-weight-bold mb-3 heading text-light">हमारा उद्देश </h5>
        <div className="row">
          <div className="col-12 col-sm-12 col-md col-lg col-xl">
            <div className="box">
              <div>संस्था के माध्यम से गोशालाओं का संवर्धन कार्यक्रम शुरू करना </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md col-lg col-xl">
            <div className="box">
              <div>अलग अलग सामाजिक संस्था, हाईस्कूल  मंडल इनको गोशाला का महत्व बताना और अधिक जानकारी देना</div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md col-lg col-xl">
            <div className="box">
              <div>अलग अलग प्रकार की उपयोगिता,  गोशालाओं की वजह से होने वाले फायदों को लोगो तक पहुँचाना</div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md col-lg col-xl">
            <div className="box">
              <div>गोशालाओं के द्वारा मिलाने वाले उत्पादन और संस्था को मिलने वाले उजीत लाभ करा के देना</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-12 col-md col-lg col-xl">
            <div className="box">
              <div>गोशाला विशेषज्ञ के मार्गदर्शन में शिविर का आयोजन करना </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md col-lg col-xl">
            <div className="box">
              <div>गोशालाओं के उपक्रम बढ़ने के अलग अलग योजनाए शुरू करना</div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md col-lg col-xl">
            <div className="box">
              <div>संस्था के जानकारी देने हेतु पुस्तिका प्रकाशित करना</div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md col-lg col-xl">
            <div className="box">
              <div>स्थानिक संस्था, सरकारी गैर सरकारी अधिकारिओ से मार्गदर्शन लेना </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-12 col-md col-lg col-xl">
            <div className="box">
              <div>पशु आरोग्य के बारे में मार्गदर्शन करना  </div>
            </div>
          </div>
          <div className="col">
            <div className="box">
              <div>आरोग्य शिविर लगाना </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md col-lg col-xl">
            <div className="box">
              <div>पहलेसे ही अस्तित्व में रहने वाली गोशालों को हर प्रकार की सहायता देना</div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md col-lg col-xl">
            <div className="box">
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </section>  
    </>
  )
}

export default udesh
