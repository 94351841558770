import React from 'react'

function sankalp() {
  return (
    <>
      <section id="sankalp">
      <div className="container">
        <h5 className="text-center font-weight-bold mb-3 heading">हमारा संकल्प</h5>
        <div className="row">
          <div className="col-12 col-sm-12 col-md col-lg col-xl">
            <div className="text-center">
              <img src="assets/images/sankalp1.jpg" className="img-fluid sankalpimg"/>
            <p>कत्तलखानो में जा रहे मूक पशुओं को बचाने में</p>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md col-lg col-xl">
            <div className="text-center">
              <img src="assets/images/sankalp2.jpg" className="img-fluid sankalpimg"/>
            <p >बूढ़ी गायों  को गौशाला में सुरक्षित पहुँचाने के लिए परिवहन खर्च</p>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md col-lg col-xl">
            <div className="text-center">
              <img src="assets/images/sankalp3.jpg" className="img-fluid sankalpimg"/>
            <p>जरुरत मंद गौशालाओ  को चारा, घास, खाद्य व औषधि के लिए मदद करने में</p>
            </div>
          </div> 
          <div className="col-12 col-sm-12 col-md col-lg col-xl">
            <div className="text-center">
              <img src="assets/images/sankalp4.jpg" className="img-fluid sankalpimg"/>
            <p>वर्तमान में चल रही गौशाला के विस्तारीकरण व आधुनिकीकरण करने में</p>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md col-lg col-xl">
            <div className="text-center">
              <img src="assets/images/sankalp5.jpg" className="img-fluid sankalpimg"/>
            <p>स्वयं की एक गौशाला निर्माण करने के संकल्प की और आगे बढ़ने मे</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default sankalp
