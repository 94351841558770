import React from 'react'

function pageSubHead(props) {
  return (
    <div className='container my-3'>
    <h4>
      {props.title}
    </h4>
    </div>
  )
}

export default pageSubHead
