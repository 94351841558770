import React from 'react'

function kartavya() {
  return (
    <>
    <section id="kartvya">
      <h5 className="text-center font-weight-bold mb-3 heading">जीवदया हमारे लिए एक कर्तव्य है</h5>
      <div className="container">
        <ul>
          <li>पशुहत्या पर प्रतिबन्ध लगाना हमारा कर्तव्य </li>
          <li>गाय एक चेतन है, धन अचेतन। अचेतन धन के लिए चेतन धन की रक्षा हमारा कर्तव्य</li>
          <li>मांस निर्यात रोकने हेतु हस्ताक्षर की नहीं हस्तक्षेप करना , हमारा कर्तव्य </li>
          <li>"जिओ और जीने दो "के सिद्धांत की रक्षा हमारा कर्तव्य</li>
          <li>पर्यावरण असन्तुलन रोकना हमारा कर्तव्य</li>
          <li>पशुओं का अकाल मृत्यु रोकना हमारा कर्तव्य</li>
        </ul>
      </div>
    </section>
    </>
  )
}

export default kartavya
