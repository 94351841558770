import React from 'react'
import CardBody from './CardBody';
import {Row,Col} from 'react-bootstrap'


function imggallery(props) {
  return (
    <>
      <section id="imggallery">
      <div className="container">
        <h5 className="text-center font-weight-bold mb-3 heading mt-3">गैलरी</h5>
        <ul id="myTabs" className="nav nav-pills nav-justified" role="tablist" data-tabs="tabs">
          {/* {props.gallaryData.map((element,index)=>(
            <li><a href={`#gallary${index}`} data-toggle="tab" ></a></li>
          ))} */}
          {props.gallaryData.map((element, index) => {
            return <Col key={index}><li key={index}><CardBody key={element.gallary_id} src={element.gallary_images[0]?.path} title={element.title} index={index} images={element.gallary_images} /> </li></Col>;
            // return null;
          })}
        </ul>
        <div className="tab-content mt-3">
        {props.gallaryData.map((element,index)=>{
         return <div role="tabpanel" className="tab-pane" id={`gallary${index}`}>
            <div className="container">
              <div className="row">
                {
                  element.gallary_images?element.gallary_images.map((img,i) => (
                    <div className="col-12 col-sm-12 col-md col-lg col-xl">
                      <a href={img.path} data-toggle="lightbox" data-gallery="gallery" className="col-md-4">
                      <img src={img.path} className="img-fluid rounded" />
                      </a>
                  </div>
                  )):null
                }
              </div>
            </div>
          </div>
        })}
      </div>
      </div>
    </section>
    </>
  )
}

export default imggallery
