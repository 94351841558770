import React from 'react'

function donateBg() {
  return (
    <>
     <section id="donatebg">
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 box1">
            <div className="headingtext">गायों को बचाने के लिए<br/>धन दान करें</div>
            <div className="information">गायों को बचाने हेतु धनराशि दान कीजिये.अपना योगदान
                  <br/>दीजिये, गायों को बचाने में सहयोग दीजिये
            </div>
            <div className="donatebutton">दान करें अभी</div>
          </div>
          <div className="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
            <div className="row">
              <div className="box2">
                <img src="assets/images/sankalp3.jpg" className="image1"/>
              </div>
              <div>
                <img src="assets/images/cow1.jpg" className="image2"/>
              </div>
          </div>
          </div>
        </div>
      </div>
    </section> 
    </>
  )
}

export default donateBg
