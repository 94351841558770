import React from 'react'

function aboutUs() {
  return (
    <>
    <section id="aboutus">
      <h5 className="text-center font-weight-bold mb-3 heading">हम कौन है</h5>
      <div className="container">
          <p style={{lineHeight: "33px"}}>इन मूक प्राणियों की जीने इच्छा को देखते हुए उनके संरक्षण हेतु "संत  शिरोमणि आचार्य गुरुवार श्री विद्यासागर महाराज जी के परम प्रभावक शिष्य समता शिरोमणी १०८ श्री नियमसागर जी महाराज जी, १०८ श्री वृषभ सागरजी महाराजजी एवं १०८ सुपार्श्व सागरजी महाराजजी के सानिध्य में
पूना (निगड़ी) में हुए २०१२ के चातुर्मास में संपूर्ण जैन समाज  को एकत्र करने एवं उनकी प्रेरणा से गौ रक्षा का संकल्प लिया । </p>
      </div>
    </section>
    </>
  )
}

export default aboutUs
