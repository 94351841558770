import React, { useState ,useEffect} from 'react'
import {Row,Col,Form} from 'react-bootstrap'
import Header from '../Component/Header';
import PageHead from '../Component/pageHead';
import Footer from '../Component/footer';
import {useFormik} from 'formik';
import {PostAction} from '../Component/PostAction';
import { useHistory } from "react-router-dom";

const initialformData = {
    username:'',
    password:'',
    usertype:'2',
  }
function Login() {
    const [formData, setFormData] = useState(initialformData);
    const postUrl = "user/loginCheck"
    let history = useHistory();

    const {values,errors,touched,handleChange,handleBlur,handleSubmit,setFieldValue,} = useFormik({
        initialValues:formData,
        // validationSchema: donationSchema,
        onSubmit:(values)=>{
          const callPOSTAPI = async () => {
          const res = await PostAction('post',values,postUrl)
          const response = res.response
          response.map((item,index)=>{
          sessionStorage.setItem("id", item.id);
          sessionStorage.setItem("name", item.name);
          sessionStorage.setItem("first_name", item.first_name);
          sessionStorage.setItem("last_name", item.last_name);
          sessionStorage.setItem("pan_number", item.pan_number);
          sessionStorage.setItem("adhar_card", item.adhar_card);
          sessionStorage.setItem("mobile", item.mobile);
          sessionStorage.setItem("address1", item.address1);
          sessionStorage.setItem("address2", item.address2);
          sessionStorage.setItem("city", item.city);
          sessionStorage.setItem("zip_no", item.zip_no);
          sessionStorage.setItem("state", item.state);
          sessionStorage.setItem("country_id", item.country_id);
          sessionStorage.setItem("country_name", item.country_name);
          sessionStorage.setItem("state", item.state);
          sessionStorage.setItem("state_name", item.state_name);
          sessionStorage.setItem("email_address", item.email_address);
          sessionStorage.setItem("user_role", item.user_role);
          history.push('/');
          })
            alert(res.message);
          }
          callPOSTAPI()
        }
      })
  return (
    <>
    <Header />
    <PageHead title="Member Login" />
    <div className='container'  style={{fontSize:20,minHeight:"615px"}}>
    <div className='card text-center'>
    <div className="card-body">
    <Row>
        <Col><h4>Member Login</h4></Col>
    </Row>
    <Form onSubmit={handleSubmit}>
    <Row className='my-3'>
        <Col xs={12} md={4}></Col>
        <Col xs={12} md={4}>
            <label className='form-label'>Username</label>
            <input 
            className='form-control'
            type="text"
            name="username"
            value={values.username}
            onChange={handleChange}
            />
        </Col>
    </Row>
    <Row className='my-3'>
        <Col xs={12} md={4}></Col>
        <Col xs={12} md={4}>
            <label className='form-label'>Password</label>
            <input 
            className='form-control'
            type="password"
            name="password"
            value={values.password}
            onChange={handleChange}
            />
        </Col>
    </Row>
        <button className="btn btn-primary" type='submit'>Login</button>
    </Form>
    </div>
    </div>
    </div>
   <Footer />
    </>
  
  )
}

export default Login
